export default {
    name: {
        value: "name",
        display: true, 
        label: "Organisation Name",
        component: "v-text-field"
    }, 
	logo: {
		value: "logo",
		display: true,
		component: "mw-upload",
		label: "Logo",
	},
}