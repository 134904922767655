<template>
	<v-sheet flat>
		<mw-edit
			:id="id"
			storeModule="organisations"
			:model="model"
			@saved="$emit('saved')"
			title-key="name"
			path="organisations"
		>
		</mw-edit>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Organisation from "@/models/Organisation";
import MwEdit from "@c/ui/MwEdit";

export default {
	name: "EditOrganisation",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	computed: {
		model() {
			return Organisation;
		},
	},
	components: {
		MwEdit,
    },
};
//
</script>
"
